body {
  background-color: #fff;
}

body * {
  font-family: 'Source Sans 3', sans-serif;
}

.disabled {
  pointer-events: none;
  cursor: not-allowed;
}

.ant-btn-primary {
  background-color: #1677ff !important;
}

.ant-btn-primary:disabled {
  background-color: lightgray !important;
}

.ant-select-selection-item {
  height: inherit;
}

h1 {
  margin: 1rem 0 0 0;
}

.ant-cascader,
.select {
  width: 100% !important;
}
