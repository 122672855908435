@tailwind base;
@tailwind components;
@tailwind utilities;

#root {
  position: relative;
}

::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: #999;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #777;
}
