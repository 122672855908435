.scroll::-webkit-scrollbar {
  width: 6px; /* Ширина полосы прокрутки */
}

/* Стили для ползунка прокрутки */
.scroll::-webkit-scrollbar-thumb {
  background-color: #ff4545; /* Цвет ползунка прокрутки */
  border-radius: 15px; /* Радиус скругления углов ползунка */
}

/* Стили для трека прокрутки */
.scroll::-webkit-scrollbar-track {
  background-color: #e6e7e8; /* Цвет фона трека прокрутки */
  border-radius: 15px; /* Радиус скругления углов  */
}
